import * as React from "react";
import { Privacy, Layout } from "../components";
import Seo from "../components/Seo";

const TermsPage = () => {
  return (
    <Layout>
      <Seo
        title="アプリケーション・プライバシーポリシー"
        description="当社が定めるプライバシーポリシーをご確認いただけます。"
      />
      <Privacy />
    </Layout>
  );
};

export default TermsPage;
